import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import excel from '../../../../../../../common/src/assets/image/excel.png';
import excel_import_example from '../../../../../../../common/src/assets/image/excel_import_example.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing events and tasks imported from Excel files.
      </p>
      <h3>How to format Excel files as calendar data sources</h3>
      <p>
        PrintableCal will import events and tasks that are found on the first worksheet of a connected Excel file. The worksheet must be formatted with column header names on the first row, followed by any number of event or task
        rows. An example spreadsheet is shown below:<br/>
        <Image alt="" src={excel_import_example} style={{ width: 899 }} />
      </p>
      <p>The following column header names and variations are supported by PrintableCal and will be automatically mapped to the appropriate field when importing. The fields can be defined in any order. The only mandatory fields are Title and Start (or Start Date and Start Time). All other fields are optional. If columns have other names, the columns can be manually mapped to the appropriate target fields when connecting the Excel file to PrintableCal.
      </p>
      <table className="table">
        <thead>
        <tr>
          <th>Field Name</th>
          <th>Variations</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><strong>Title</strong></td>
          <td>Subject, Summary, Event</td>
          <td>The event title text.</td>
        </tr>
        <tr>
          <td><strong>Location</strong></td>
          <td>Address, Room</td>
          <td>The event location.</td>
        </tr>
        <tr>
          <td><strong>Category</strong></td>
          <td>Categories</td>
          <td>The event category. If there are multiple categories, separate them with semicolons.
            Example: "Work; Important; Follow-up"
          </td>
        </tr>
        <tr>
          <td><strong>Description</strong></td>
          <td>Body, Message, Detail, Details, Notes, Memo</td>
          <td>The event description.</td>
        </tr>
        <tr>
          <td><strong>Start</strong></td>
          <td>Start Date/Time, Starting Date/Time, Begin</td>
          <td>The event starting date and time. For an all-day event, just the date is needed. The
            format should match the date/time format used by Windows, as specified in the
            Control Panel, under "Clock, Language, and Region &gt; Change date, time, or
            number formats".
            <br/><br/>
                Use this field when you want to combine the starting date and time into a single
                field. If you want to separate the date and time fields, use the <strong>Start
                Date</strong> and <strong>Start Time</strong> fields instead.
          </td>
        </tr>
        <tr>
          <td><strong>Start Date</strong></td>
          <td>Starting Date, Begin Date</td>
          <td>The event starting date.</td>
        </tr>
        <tr>
          <td><strong>Start Time</strong></td>
          <td>Starting Time, Begin Time</td>
          <td>The event starting time. Leave blank for all-day events.</td>
        </tr>
        <tr>
          <td><strong>End</strong></td>
          <td>End Date/Time, Ending Date/Time, Finish, Due</td>
          <td>The event ending date and time. For an all-day event, just the date is needed. The
            format should match the date/time format used by Windows, as specified in the
            Control Panel, under "Clock, Language, and Region &gt; Change date, time, or
            number formats".
            <br/><br/>
                Use this field when you want to combine the ending date and time into a single
                field. If you want to separate the date and time fields, use the <strong>End
                Date</strong> and <strong>End Time</strong> fields instead. If none of these
                fields are included, the <strong>Duration</strong> field will be used to calculate
                the ending date/time.
          </td>
        </tr>
        <tr>
          <td><strong>End Date</strong></td>
          <td>Ending Date, Finish Date, Due Date</td>
          <td>The event ending date.</td>
        </tr>
        <tr>
          <td><strong>End Time</strong></td>
          <td>Ending Time, Finish Time, Due Time</td>
          <td>The event ending time. Leave blank for all-day events.</td>
        </tr>
        <tr>
          <td><strong>Duration</strong></td>
          <td>Length</td>
          <td>The event duration. The value should be formatted as "N span", where N is
            a number of span is "minutes", "hours", "days", "weeks",
            or "months". Example: "30 minutes".<br/><br/>
                This field can be used as an alternative to the <strong>End</strong>, <strong>End
                Date</strong>, and <strong>End Time</strong> fields. The ending date/time will
                be calculated when this field is present.
          </td>
        </tr>
        <tr>
          <td><strong>Time Zone</strong></td>
          <td>Timezone, Time-Zone, Timezone Name, Timezone ID, TZ, TZID</td>
          <td>The event time zone. The value should be one of the <a
          href="https://msdn.microsoft.com/en-us/library/ms912391(v=winembedded.11).aspx" target="_blank">time zone
            names supported by Windows</a>, such as "Central
            Standard Time".
            Each event can have a time zone specified. A mix of multiple time zones is
            supported.
            <br/><br/>
                If not specified, the time zone be assumed to be the same local time zone used by Windows.
          </td>
        </tr>
        <tr>
          <td><strong>All Day</strong></td>
          <td>All Day Event, Entire Day, All-Day, All-Day Event</td>
          <td>An all-day flag used for events that occur over the span of an entire day, such as
            birthdays and holidays.
            <br/><br/>
                Values that are blank, "No", or "False" will be treated as non
                all-day events, while values of "X", "Yes", or "True"
                will be treated as all-day. This format is also used by the{' '}
                <strong>Reminder</strong>, <strong>Recurs Daily</strong>, <strong>Recurs
                Weekly</strong>, <strong>Recurs Monthly</strong>, and <strong>Recurs
                Yearly</strong> fields.
          </td>
        </tr>
        <tr>
          <td><strong>Required Attendees</strong></td>
          <td>Attendee, Attendees, Recipients, Required, To</td>
          <td>Required attendees for a meeting request. Multiple attendees can be separated with
            semicolons. Attendees can be specified with a name followed by an email address
            (example: "John Doe &lt;johndoe@gmail.com&gt;") or just an email
            address.<br/>
              <br/>
                Note:
                Printable doesn't send meeting notices.
          </td>
        </tr>
        <tr>
          <td><strong>Optional Attendees</strong></td>
          <td>Optional, CC</td>
          <td>Optional attendees for a meeting request, formatted the same as <strong>Required
            Attendees.</strong>.
          </td>
        </tr>
        <tr>
          <td><strong>Organizer</strong></td>
          <td>Meeting Organizer, From</td>
          <td>The meeting organizer, formatted the same as <strong>Required Attendees.</strong>.
          </td>
        </tr>
        <tr>
          <td><strong>Recurrence</strong></td>
          <td>Recurrence Summary, Recurs, Repeat, Repeats</td>
          <td>This field specifies a basic recurrence pattern for the event. The first occurrence
            of the series is specified by the <strong>Start</strong> field. Supported values are{' '}
            <strong>Daily</strong>, <strong>Weekly</strong>, <strong>Monthly</strong>, and <strong>Yearly</strong>.
            This field should be blank for events that don't recur.<br/>
              <br/>
                This field can be used as an alternative to the separate <strong>Recurs
                Daily</strong>, <strong>Recurs Weekly</strong>, <strong>Recurs Monthly</strong>,
                and <strong>Recurs Yearly</strong> fields.
          </td>
        </tr>
        <tr>
          <td><strong>Recurs Daily</strong></td>
          <td>Daily, Recur Daily</td>
          <td>A flag indicating an event that recurs daily. The first occurrence and day-of-week
            are determined by the <strong>Start</strong> field.
          </td>
        </tr>
        <tr>
          <td><strong>Recurs Weekly</strong></td>
          <td>Weekly, Recur Weekly</td>
          <td>A flag indicating an event that recurs weekly. The first occurrence and day-of-week
            are determined by the <strong>Start</strong> field.
          </td>
        </tr>
        <tr>
          <td><strong>Recurs Monthly</strong></td>
          <td>Monthly, Recur Monthly</td>
          <td>A flag indicating an event that recurs monthly. The first occurrence and
            day-of-month are determined by the <strong>Start</strong> field.
          </td>
        </tr>
        <tr>
          <td><strong>Recurs Yearly</strong></td>
          <td>Yearly, Recur Yearly</td>
          <td>A flag indicating an event that recurs yearly. The first occurrence and day-of-year
            are determined by the <strong>Start</strong> field.
          </td>
        </tr>
        <tr>
          <td><strong>Recurrence End Date</strong></td>
          <td>Recurrence End, Recurrence Stop Date, Recurrence Stop</td>
          <td>The date when the specified recurrence pattern should end. No additional events will
            occur after the specified date. Leave blank if the event isn't recurring,
            doesn't have a known ending date, or if a value has been specified in the <strong>Number
              of Occurrences</strong> field.
          </td>
        </tr>
        <tr>
          <td><strong>Number of Occurrences</strong></td>
          <td>Max Occurrences, Maximum Occurrences, Occurrence Count, Recurrence Count,
            Occurrences
          </td>
          <td>The maximum number of occurrences of the specified recurring series. Leave blank if
            the event isn't recurring, doesn't have a known number of occurrences, or if a
            value has been specified in the <strong>Recurrence End Date</strong> field.
          </td>
        </tr>
        <tr>
          <td><strong>Recurrence Interval</strong></td>
          <td>Recur Interval, Repeat Interval, Interval</td>
          <td>A number indicating the interval between occurrences of the specified recurrence
            pattern. For example, if the recurrence pattern is daily and recurrence interval is
            set to 4, the recurring event will repeat every 4 days. Leave this number blank if
            the event isn't recurring or should repeat without an interval.
          </td>
        </tr>
        </tbody>
      </table>
      <h3>How to connect PrintableCal to an Excel calendar data source</h3>
      <p>
        To configure PrintableCal to import from an Excel file when creating
        calendars, follow these steps:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the <strong>Local -&gt; Excel File </strong>
          option.
        </li>
        <li>Select the Excel file to be imported.</li>
        <li>
          <span>
            PrintableCal will try to determine the mapping of columns found in
            the selected Excel file. You can override the column mapping as
            needed. Since PrintableCal only uses a subset of the possible data,
            some fields won't have any mapping to PrintableCal. The mapping for
            non-applicable fields can be set blank to be ignored, or set to the{' '}
            <strong>Description </strong>field to have their contents appended
            to the item descriptions that appear in some templates.
          </span>
          <br />
          <Image alt="" src={excel} style={{ width: 690 }} />
        </li>
        <li>
          If the OK button is disabled, make sure mappings to the{' '}
          <strong>Title</strong> and <strong>Start </strong>(or{' '}
          <strong>Start Date</strong>) fields are specified. These are the only
          required fields.
        </li>
        <li>
          ​Click the <strong>OK </strong>button to add the selected Excel File
          to PrintableCal's list of available calendar sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Excel-File"
      commentsId="commentsplus_post_1199_489"
      title="Print Calendars with Events and Tasks from Excel Files"
      description="PrintableCal can create printable calendars containing events and tasks imported from Excel files."
      keywords="Excel calendar, printable calendars, calendar templates, Excel calendar, Word calendar, best printable calendar"
      content={content}
    />
  );
};

export default Documentation;
